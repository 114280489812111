import { ApplicationMemberFragment, UpsertApplicationMembersInput, Trinary } from '@app/data/types';
import { getFullName } from '@app/utils';

export interface FormValues {
  id: string;
  uiQuestionsToDisplay: object;
  fullName: string;

  isReceiveItu?: Trinary | null;
  isEligibleForItu?: Trinary | null;
  isPersonRecognizedTribe?: Trinary | null;
  federallyRecognizedTribeState?: string;
  federallyRecognizedTribeName?: string;
}

/**
 * Given a singular application member,
 * formats the form values for Medicaid CHIP program questions
 *
 * @param member
 * @returns
 */
export const getInitialValues = (member: ApplicationMemberFragment): FormValues => {
  return {
    id: member.id,
    uiQuestionsToDisplay: member.uiQuestionsToDisplay,
    fullName: getFullName(member),
    isReceiveItu: member.isReceiveItu || null,
    isEligibleForItu: member.isEligibleForItu || null,
    isPersonRecognizedTribe: member.isPersonRecognizedTribe || null,
    federallyRecognizedTribeState: member.federallyRecognizedTribeState || '',
    federallyRecognizedTribeName: member.federallyRecognizedTribeName || '',
  };
};

/**
 * Given the application ID and form values for a single member,
 * generates the input for upserting application members
 *
 * @param applicationID
 * @param values
 * @returns
 */
export const formatPayload = ({
  applicationID,
  values,
}: {
  applicationID: string;
  values: FormValues;
}): UpsertApplicationMembersInput => {
  return {
    applicationID,
    applicationMembersInput: [
      {
        id: values.id,
        isReceiveItu: values.isReceiveItu,
        isEligibleForItu: values.isEligibleForItu,
        isPersonRecognizedTribe: values.isPersonRecognizedTribe,
        federallyRecognizedTribeState: values.federallyRecognizedTribeState,
        federallyRecognizedTribeName: values.federallyRecognizedTribeName,
      },
    ],
  };
};
