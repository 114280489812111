import { Trinary } from '@app/data';
import { optionPresets } from '@app/forms';

export const fields = [
  {
    name: 'american-indian-alaskan-native',
    type: 'section',
    title: 'American Indian/Alaskan Native',
    dependencies: ['uiQuestionsToDisplay'],
    display: ({ uiQuestionsToDisplay }) => {
      return uiQuestionsToDisplay?.['237'] || uiQuestionsToDisplay?.['210'];
    },
    fields: [
      {
        testID: '237',
        copy: 'catch.ede.HealthEnrollment',
        name: 'isReceiveItu',
        type: 'radio',
        required: true,
        dependencies: ['uiQuestionsToDisplay', 'fullName'],
        display: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['237'],
        label: ({ fullName }) => ({ id: '237_L', data: { fullName } }),
        options: optionPresets.YES_NO_TRINARY,
      },
      {
        testID: '238',
        copy: 'catch.ede.HealthEnrollment',
        name: 'isEligibleForItu',
        type: 'radio',
        required: true,
        dependencies: ['uiQuestionsToDisplay', 'fullName', 'isReceiveItu'],
        display: ({ uiQuestionsToDisplay, isReceiveItu }) =>
          uiQuestionsToDisplay?.['237'] && isReceiveItu === Trinary.No,
        label: ({ fullName }) => ({ id: '238_L', data: { fullName } }),
        options: optionPresets.YES_NO_TRINARY,
      },
      {
        testID: '210',
        copy: 'catch.ede.HealthEnrollment',
        name: 'isPersonRecognizedTribe',
        type: 'radio',
        required: true,
        dependencies: ['uiQuestionsToDisplay', 'fullName'],
        display: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['210'],
        label: ({ fullName }) => ({ id: '210_L', data: { fullName } }),
        help: { id: '210_T' },
        options: optionPresets.YES_NO_TRINARY,
      },
      {
        testID: '211',
        copy: 'catch.ede.HealthEnrollment',
        name: 'federallyRecognizedTribeState',
        type: 'dropdown',
        required: true,
        dependencies: ['uiQuestionsToDisplay', 'fullName', 'isPersonRecognizedTribe'],
        display: ({ uiQuestionsToDisplay, isPersonRecognizedTribe }) =>
          uiQuestionsToDisplay?.['210'] && isPersonRecognizedTribe === Trinary.Yes,
        label: ({ fullName }) => ({ id: '211_L', data: { fullName } }),
        options: optionPresets.STATES_ONLY,
      },
      {
        testID: '212',
        copy: 'catch.ede.HealthEnrollment',
        name: 'federallyRecognizedTribeName',
        type: 'text',
        required: true,
        dependencies: ['uiQuestionsToDisplay', 'fullName', 'federallyRecognizedTribeState'],
        display: ({ uiQuestionsToDisplay, federallyRecognizedTribeState }) =>
          uiQuestionsToDisplay?.['210'] && !!federallyRecognizedTribeState,
        label: ({ fullName }) => ({ id: '212_L', data: { fullName } }),
      },
    ],
  },
];
