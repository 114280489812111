export const determineCoveredNonApplicantChild = (members, isRequestingFinancialAssistance) => {
  const isSelfPrelimMedicaidEligible = (members || []).find(
    (dep) => dep.relation === 'SELF' && dep.preliminaryMedicaidStatus === 'YES',
  );

  const isChildNonApplicant = (members || []).find(
    (dep) =>
      dep.relation === 'CHILD' &&
      (dep.isRequestingCoverage === 'NO' || dep.isRequestingCoverage === false),
  );

  return !!isSelfPrelimMedicaidEligible && isRequestingFinancialAssistance && !!isChildNonApplicant;
};

export const determineDeprivationNavigation = (uiQuestionsToDisplay) => {
  return uiQuestionsToDisplay?.['242'];
};

export const determineOutsideHousehold = (members) => {
  const hasSpouse = (members || []).some((dep) => dep.relation === 'SPOUSE');

  const isSelfPrelimMedicaidEligible = (members || []).find(
    (dep) =>
      dep.relation === 'SELF' &&
      (dep.preliminaryMedicaidStatus === 'YES' ||
        dep.preliminaryEmergencyMedicaidStatus === 'YES') &&
      !dep.isPregnant,
  );

  const hasChildDependentsMedicaidEligible = (members || []).some(
    (dep) =>
      dep.relation === 'CHILD' &&
      (dep.preliminaryMedicaidStatus === 'YES' ||
        dep.preliminaryEmergencyMedicaidStatus === 'YES') &&
      dep.age <= 18,
  );

  return hasChildDependentsMedicaidEligible && isSelfPrelimMedicaidEligible && !hasSpouse;
};

export const determineOtherCoverage = (members) => {
  return members.some(
    (dep) =>
      dep.preliminaryAPTCStatus === 'YES' ||
      dep.preliminaryCHIPStatus === 'YES' ||
      dep.preliminaryMedicaidStatus === 'YES',
  );
};

export const hasMedicaidChipProgram = (members) => {
  const medicaidChipProgramQuestions = ['237', '210'];

  return members.some((member) =>
    medicaidChipProgramQuestions.some(
      (questionId) => member.uiQuestionsToDisplay?.[questionId] === true,
    ),
  );
};

export const hasAnyCitizens = (members) => {
  return members.some((dep) => dep.isCitizen);
};

export const isMarketplacePrelim = (members) => {
  return members.some((d) => d.preliminaryQHPStatus === 'YES' || d.preliminaryAPTCStatus === 'YES');
};
