import { useMemo } from 'react';
import { HealthApplicationQuery, getMembers, mutations, useMutation, useQuery } from '@data';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { getInitialValues, formatPayload } from './medicaidChipProgramUtils';
import { fields } from './medicaidChipProgramFields';
import { Route } from '@types';

const MedicaidChipProgram = ({ applicationID, handleNext }) => {
  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const members = useMemo(() => {
    return getMembers(data) || [];
  }, [data?.viewerTwo?.health?.application]);

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={upserting}
      title="Additional questions"
      subtitle="For your Medicaid and CHIP eligibility"
      members={members}
      fields={fields}
      data={{}}
      getInitialValues={getInitialValues}
      onNext={async (values) => {
        const input = formatPayload({ applicationID, values });
        await upsert({ variables: { input } });
      }}
      onComplete={handleNext}
    />
  );
};

export const MedicaidChipProgramView = {
  name: Route.EDE_MEDICAID_CHIP_PROGRAM,
  component: MedicaidChipProgram,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Additional questions',
  },
};
