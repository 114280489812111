import React, { useCallback, useEffect, useMemo } from 'react';
import {
  healthRouteMap,
  navigate,
  paths,
  sheets,
  Stack,
  StackComponent,
  StackDefinition,
  stacks,
  useCurrentParams,
  useCurrentRoute,
  useSheet,
} from '@navigate';
import { createLogger } from '@app/utils';
import {
  HealthApplicationQuery,
  HealthPathwayType,
  UpsertHealthApplication,
  useMutation,
  useQuery,
} from '@app/data';
import { useHealthStore } from '@hooks/useHealthStore';
import { useStackAccentColor } from '@app/hooks';
import { Route } from '@types';

import { AgreementsView } from './routes/agreements/AgreementsView';
import { ApplicationImportView } from './routes/ApplicationImportView';
import { APTCAgreementView } from './routes/APTCAgreementView';
import { ConfirmApplicantsView } from './routes/confirmApplicants/ConfirmApplicantsView';
import { ConfirmationView } from './routes/ConfirmationView';
import { CoverageInfoView } from './routes/CoverageInfoView';
import { DeductionListView } from './routes/deductionList/DeductionListView';
import { DependentsCurrentCoverageView } from './routes/dependentsCurrentCoverage/DependentsCurrentCoverageView';
import { HealthDocsView } from './routes/docs/HealthDocsView';
import { EligibilityResultsView } from './routes/eligibility/EligibilityResultsView';
import { HealthErrorView } from './routes/HealthErrorView';
import { HealthIntroView } from './routes/ApplicationIntroView';
import { HealthPlanSelectionView } from './routes/HealthPlanSelectionView';
import { HouseholdView } from './routes/HouseholdView';
import { IdInfoView } from './routes/idInfo/IdInfoView';
import { ApplicationIdentityView } from './routes/idInfo/ApplicationIdentityView';
import { ImmigrationDetailsView } from './routes/immigrationDetails/ImmigrationDetailsView';
import { IncomeConfirmView } from './routes/incomeConfirm/IncomeConfirmView';
import { IncomeDetailsView } from './routes/incomeDetails/IncomeDetailsView';
import { IncomeDiscrepancyView } from './routes/incomeDiscrepancy/IncomeDiscrepancyView';
import { IncomeListView } from './routes/incomeList/IncomeListView';
import { MedicaidDenialView } from './routes/medicaidDenial/MedicaidDenialView';
import { MedicalBillsView } from './routes/medicalBills/MedicalBillsView';
import { MemberInfoView } from './routes/memberInfo/MemberInfoView';
import { MemberQuestionsView } from './routes/memberQuestions/MemberQuestionsView';
import { OtherCoverageView } from './routes/otherCoverage/OtherCoverageView';
import { OutsideHouseholdView } from './routes/outsideHousehold/OutsideHouseholdView';
import { PrivacyAgreementView } from './routes/PrivacyAgreementView';
import { ProAssistanceView } from './routes/proAssistance/ProAssistanceView';
import { ReviewApplicationView } from './routes/ReviewApplicationView';
import { ScreeningView } from './routes/screening/ScreeningView';
import { SEPOutDateView } from './routes/SEPOutDateView';
import { MedicaidChipProgramView } from './routes/medicaidChipProgram/MedicaidChipProgramView';
import { SEPView } from './routes/sep/SEPView';
import { SplitEligibilityView } from './routes/SplitEligibilityView';
import { SuccessView } from './routes/SuccessView';
import { WorkHoursView } from './routes/WorkHoursView';
import { WindowShoppingView } from './routes/WindowShoppingView';
import { VoterRegistrationView } from './routes/voterRegistration/VoterRegistrationView';
import { AetnaFinancialOnboardingView } from './routes/AetnaFinancialOnboardingView';
import { HealthAlternateEmailView } from './routes/HealthAlternateEmailView';
import { IdUploadView } from './routes/identity/IdUploadView';
import { AppSubmittedView } from './routes/submitted/AppSubmittedView';

const Log = createLogger('health-application');

const config: StackDefinition = {
  stackName: stacks.HEALTH_APPLICATION_STACK,
  options: { layout: 'page', navMode: 'flow' },
  screens: [
    // create app pages
    HealthIntroView,
    CoverageInfoView,
    PrivacyAgreementView,

    // screening
    HouseholdView,
    ScreeningView,
    ProAssistanceView,

    // id proofing
    IdInfoView,
    ApplicationIdentityView,
    IdUploadView,

    // application resolution
    ApplicationImportView,
    ConfirmApplicantsView,
    WindowShoppingView,

    // member questions
    MemberInfoView,
    MemberQuestionsView,
    ImmigrationDetailsView,
    MedicaidDenialView,

    // income questions
    IncomeListView,
    DeductionListView,
    IncomeDetailsView,
    IncomeConfirmView,
    IncomeDiscrepancyView,

    // SEP
    SEPView,

    // medicaid program (in order)
    MedicalBillsView,
    DependentsCurrentCoverageView,
    OutsideHouseholdView,
    WorkHoursView,
    OtherCoverageView,

    // newly designed pages
    MedicaidChipProgramView,

    // submit
    VoterRegistrationView,
    ReviewApplicationView,
    AgreementsView,

    // eligibility and enrollment
    AppSubmittedView,
    EligibilityResultsView,
    SEPOutDateView,
    SplitEligibilityView,
    HealthPlanSelectionView,
    APTCAgreementView,
    ConfirmationView,
    SuccessView,
    AetnaFinancialOnboardingView,

    // misc.
    HealthErrorView,
    HealthDocsView,

    //GA
    HealthAlternateEmailView,
  ], // @todo E-3445 typescript
};

/**
 *
 * E D E
 * H E A L T H / A P P L I C A T I O N
 * Health Application Stack (EDE Application)
 *
 * @see https://www.figma.com/file/hIcsrgfR32jFVqhcBiyBJl/EDE-and-Health-Explorer
 * @see https://www.notion.so/catchco/EDE-ba3c7980c7d049c7af57ce1abaa5ca0f
 * @see cms_ui_toolkit
 *
 */
const HealthApplicationStack: StackComponent = () => {
  const { applicationID, setID, setHealthContext } = useHealthStore();
  const route = useCurrentRoute();
  const params = useCurrentParams();
  const { open: openSheet } = useSheet();
  const accentColor = useStackAccentColor(config.stackName);

  // note: we purposefully do not include applicationID on the
  // dependencies array here because we don't want an update to the applicationID
  // to inadvertently trigger a resetting of the ID to the params applicationID
  useEffect(() => {
    // these routes don't need a specified application ID
    const canSkipAppID = [
      Route.EDE_INTRO,
      Route.EDE_COVERAGE_INFO,
      Route.EDE_HELP,
      Route.HEALTH_DOCS,
    ].includes(route);

    // reroute if misisng app ID and the current route requires it
    if (!applicationID && !params.applicationID && !canSkipAppID) {
      Log.warn('Missing health application id');
      navigate(Route.EDE_INTRO);
    }

    // anytime the currently stored ID doesnt match the params, update it
    if (params.applicationID && applicationID !== params.applicationID) {
      Log.debug('Setting health application ID from params');
      setID(params.applicationID);
    }
  }, [params, route]);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  /**
   * Handles refetching eligibility when necessary
   * If the user is on a post-submit app page and the should refresh flag is true,
   * open the sheet that handles refetching eligibility
   */
  useEffect(() => {
    const postSubmitRoutes = [Route.EDE_RESULTS, Route.EDE_PLANS, Route.EDE_ENROLL];
    const application = data?.viewerTwo?.health?.application;

    if (postSubmitRoutes.includes(route) && application?.shouldRefetchEligibility) {
      openSheet(sheets.REFETCH_ELIGIBILITY, { applicationID: application?.id });
    }
  }, [route, data?.viewerTwo?.health?.application?.shouldRefetchEligibility]);

  const isGA = useMemo(() => {
    if (data?.viewerTwo?.health?.application?.isGeorgiaAccess) {
      setHealthContext('GA');
      return true;
    } else {
      const coverageState = data?.viewerTwo?.health?.application?.coverageState;
      const pathway = data?.viewerTwo?.health?.application?.pathway;

      if (pathway === HealthPathwayType.StateApp && coverageState) {
        setHealthContext(coverageState);
      } else {
        setHealthContext('FFM');
      }
      return false;
    }
  }, [data?.viewerTwo?.health?.application?.isGeorgiaAccess]);

  const [updateApplication] = useMutation(UpsertHealthApplication);

  // automatically updates the last used route
  useEffect(() => {
    // dont set these as last used route
    const skipRouteTracking = [
      Route.EDE_INTRO,
      Route.EDE_WINDOW_SHOPPING,
      Route.EDE_HELP,
      Route.EDE_SEP,
      Route.HEALTH_DOCS,
      Route.EDE_SEP_OUT_OF_DATE,
      Route.EDE_ALTERNATE_EMAIL,
    ];

    // whenever the route changes, update the last used route
    if (applicationID && !skipRouteTracking.includes(route)) {
      const lastUsedRoute = paths[route]?.replace('/health/application/', '');

      // set isStartedApplication to true AFTER id proofing
      const healthOrder = Object.keys(healthRouteMap);
      const currentIndex = healthOrder.indexOf(lastUsedRoute);
      const identityIndex = healthOrder.indexOf('identity');

      updateApplication({
        variables: {
          input: {
            id: applicationID,
            lastUsedRoute,
            isStartedApplication: currentIndex > identityIndex ? true : undefined,
          },
        },
      });
    }
  }, [route, applicationID]);

  // temporary for now: let's pass down the enroll ID for cases where it isn't set
  const enrollId = useMemo(() => {
    // if the application query hasn't resolved, fall back to the navigation enroll id if it exists
    return data?.viewerTwo?.health?.application?.enrollment?.id || params?.enrollId;
  }, [data?.viewerTwo?.health?.application?.enrollment, params]);

  const applicantID = useMemo(() => {
    return data?.viewerTwo?.health?.application?.applicant?.id;
  }, [data?.viewerTwo?.health?.application?.applicant]);

  const coverageYear = useMemo(() => {
    return data?.viewerTwo?.health?.application?.coverageYearNumber;
  }, [data?.viewerTwo?.health?.application?.coverageYearNumber]);

  const coverageState = useMemo(() => {
    return data?.viewerTwo?.health?.application?.coverageState;
  }, [data?.viewerTwo?.health?.application?.coverageState]);

  const pathway = useMemo(() => {
    return data?.viewerTwo?.health?.application?.pathway;
  }, [data?.viewerTwo?.health?.application?.pathway]);

  const getNextRoute = useCallback(
    (route, options) => {
      switch (route) {
        case Route.EDE_PRIVACY:
          return Route.EDE_HOUSEHOLD;
        case Route.EDE_HOUSEHOLD:
          return Route.EDE_SCREENING;
        case Route.EDE_MEMBER_INCOME_LIST:
          return Route.EDE_MEMBER_DEDUCTIONS_LIST;
        case Route.EDE_MEMBER_DEDUCTIONS_LIST:
          if (options?.hasAnyIncome || options?.hasAnyDeductions) {
            return Route.EDE_MEMBER_INCOME_DETAILS;
          } else {
            return Route.EDE_MEMBER_INCOME_CONFIRM;
          }
        case Route.EDE_MEDICAID_CHIP_PROGRAM:
          return Route.EDE_SEP;
        case Route.EDE_VOTER_REGISTRATION:
          return Route.EDE_REVIEW;
        case Route.EDE_AGREEMENTS:
          if (pathway === HealthPathwayType.StateApp) {
            return Route.EDE_SUBMITTED;
          } else {
            return Route.EDE_RESULTS;
          }

        case Route.EDE_APTC_AGREEMENT:
          return Route.EDE_ENROLL;
        default:
          return undefined;
      }
    },
    [data, pathway],
  );

  const handleNext = useCallback(
    (options) => {
      const next = getNextRoute(route, options);

      if (next) {
        // handle navigating to next route, pass through enrollId for compatibility for now
        navigate(next, { enrollId });
      } else {
        Log.debug(`Next not handled for route:${route}`);
      }
    },
    [route, enrollId, getNextRoute],
  );

  const stackOptions = {
    ...config.options,
    layout: 'page',
    navMode: 'flow',
    accentColor: accentColor || 'coverage',
  };

  const stackData = useMemo(() => {
    return {
      loading,
      applicationID,
      applicantID,
      coverageYear,
      coverageState,
      enrollId,
      pathway,
      handleNext,
      isGA,
    };
  }, [loading, applicationID, applicantID, coverageYear, coverageState, isGA, pathway, handleNext]);

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={stackOptions}
      data={stackData}
    />
  );
};

HealthApplicationStack.config = config;
export default HealthApplicationStack;
