import gql from 'graphql-tag';
import {
  CoverageEndedReason,
  InsuranceMarketType,
  LanguageType,
  MetalLevel,
  PreliminaryStatus,
} from '@types';

export interface ApolloHealthPlanDetails {
  planID: string;
  name: string;
  title: string; // formatted name
  type: string;
  metal: string;
  metalLevel: MetalLevel;
  isStandard: boolean;
  premium: number;
  premiumWithCredit: number;
  aptcEligiblePremium: number;
  ehbPremium: number;
  hsaEligible: boolean;
  brochureURL: string;
  benefitsURL: string;
  individualDeductible: number;
  familyDeductible?: number;
  individualMax: number;
  familyMax?: number;
  issuer: {
    id: string;
    name: string;
    title: string;
  };
  benefits: Array<{
    name: string;
    covered: boolean;
    costSharings: Array<{
      coinsuranceOptions: string;
      coinsuranceRate: number;
      copayAmount: number;
      copayOptions: string;
      networkTier: string;
      csr: string;
      displayString: string;
    }>;
    explanation: string;
    hasLimits: boolean;
    limitUnit: string;
    limitQuantity: number;
  }>;
  moops: Array<{
    amount: number;
    familyCost: string;
    type: string;
  }>;
  deductibles: Array<{
    amount: number;
    familyCost: string;
    type: string;
  }>;
  qualityRating: {
    available: boolean;
    globalRating: number;
    clinicalQualityManagementRating: number;
    enrolleeExperienceRating: number;
    planEfficiencyRating: number;
  };
}

export interface ApolloScoredHealthPlan {
  planID: string;
  healthPlan: ApolloHealthPlanDetails;
  isCatchRecommended: boolean;
  isBudgetPick: boolean;
  isComprehensive: boolean;
  providerCoverage: {
    npi: string;
    name: string;
    isCovered: boolean;
  };
  drugCoverage: {
    rxcui: string;
    name: string;
    isCovered: boolean;
    isGenericCovered: boolean;
  };
}

export interface ApolloPlanSearch {
  plans: Array<ApolloScoredHealthPlan>;
  topPlans: {
    catchRecommended: ApolloScoredHealthPlan;
    budgetPlan: ApolloScoredHealthPlan;
    comprehensivePlan: ApolloScoredHealthPlan;
  };
}

export interface ApolloEnrollmentGroup {
  id: string;
  enrollmentType: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE';
  selectedProviderPlanID: string;
  planPremium: number;
  planPremiumEffective: number;
  members: Array<{
    id: string;
    givenName: string;
    familyName: string;
    healthEnrollmentGroupID: string;
  }>;
  sortedPlans: ApolloPlanSearch;
  application: {
    id: string;
  };
}

export interface ApolloApplicationMember {
  id: string;
  applicationID?: string;
  relation?: 'SELF' | 'SPOUSE' | 'CHILD';
  maritalStatus?: string;
  email?: string;
  givenName?: string;
  middleName?: string;
  familyName?: string;
  legalName?: string;
  nameSuffix?: string;
  filerRelation?: 'CHILD' | 'STEPCHILD';
  spouseRelation?: 'CHILD' | 'STEPCHILD';
  sex?: 'FEMALE' | 'MALE';
  ssn?: string;
  age?: number;
  dob?: string; // YYYY-MM-DD
  race?: Array<string>;
  lastTobaccoUseDate?: string; // YYYY-MM-DD
  isRequestingCoverage?: boolean;
  isSmoker?: boolean;
  isCitizen?: boolean;
  writtenLanguageType?: LanguageType;
  spokenLanguageType?: LanguageType;
  contactMethod?: 'E_TEXT' | 'EMAIL' | 'TEXT';
  phone?: {
    number?: string;
    ext?: string;
    type: 'MOBILE' | 'HOME' | 'OFFICE' | 'OTHER';
  };
  secondaryPhone?: {
    number?: string;
    ext?: string;
    type: 'MOBILE' | 'HOME' | 'OFFICE' | 'OTHER';
  };
  homeAddress?: {
    street1: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  mailingAddress?: {
    street1: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  ssnAlternateName?: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffix?: string;
  };
  isPregnant?: boolean;
  isFosterCare1825?: boolean;
  isFullTimeStudentStatus?: boolean;
  incarcerationType?: string;
  isMedicaidDenied?: boolean;
  isMedicaidEnd?: boolean;
  medicaidDeniedDate?: string; // YYYY-MM-DD
  isAppliedDuringOeOrLifeChange?: boolean;
  isMedicaidEndingSoon?: boolean;
  isInfoChangeSinceMedicaidEnded?: boolean;
  isMedicaidDeniedDueToImmigration?: boolean;
  hasLivedInUS5Years?: boolean;
  hasImmigrationStatusFiveYear?: boolean;
  isImmigrationStatusChangeSinceDenied?: boolean;
  medicaidEndDate?: string; // YYYY-MM-DD
  isUnwindingSEP?: boolean;
  isLawfulPresenceStatus?: boolean;
  preliminaryMedicaidStatus: PreliminaryStatus;
  preliminaryCHIPStatus: PreliminaryStatus;
  preliminaryEmergencyMedicaidStatus: PreliminaryStatus;
  preliminaryQHPStatus: PreliminaryStatus;
  preliminaryAPTCStatus: PreliminaryStatus;
  hasAbsentParent: boolean;
  isCoverageEnded: boolean;
  coverageEndedReason?: CoverageEndedReason;
  coverageEndedReasonText?: string;
  isEnrolledInHealthCoverage?: boolean;
  otherCoverages: Array<{
    insuranceMarketType: InsuranceMarketType;
    insurancePlanName?: string;
    insurancePolicyMemberID?: string;
    insurancePolicyNumber?: string;
  }>;

  // income section
  incomeThisMonth?: boolean;
  hasDeductions?: boolean;
  annualTaxIncome?: {
    incomeAmount?: number;
  };

  // medical bills
  isUnpaidBill?: boolean;

  // dependents current coverage
  isCoveredDependentChild?: boolean;

  // work hours
  parent1WeeklyWorkHourQuantity?: number;
  parent2WeeklyWorkHourQuantity?: number;

  // sep section
  isLostCoverageLast60Days?: boolean;
  isWillLoseCoverageNext60Days?: boolean;
  isMoved?: boolean;
  isMarriedLast60Days?: boolean;
  isGainedLawfulPresence?: boolean;
  isReleasedFromIncarceration?: boolean;
  isGainDependent?: boolean;
  coverageLostDate?: string; // YYYY-MM-DD
  coverageLostDateFuture?: string; // YYYY-MM-DD
  planName?: string;
  whenMarried?: string; // YYYY-MM-DD
  hasCoverage60DaysBeforeMarriage?: boolean;
  hasLivedOutsideUSLast60DaysBeforeMarriage?: boolean;
  whenLawfulPresence?: string; // YYYY-MM-DD
  whenRelocated?: string; // YYYY-MM-DD
  hasCoverageLast60DaysBeforeRelocation?: boolean;
  isMovedFromOutsideUs?: boolean;
  whenReleasedFromIncarceration?: string; // YYYY-MM-DD
  whenBecomeADependent?: string; // YYYY-MM-DD
  previousZipcode?: string;
  previousCountyLivedName?: string;
}

export interface ApolloOpenEnrollmentDates {
  windowShoppingOpenTime: Date; // date time
  startTime: Date; // date time
  endTime: Date; // date time
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
  isWindowShopping: boolean;
  isOpenEnrollment: boolean;
  coverageYears: Array<Number>;
  oeYear: Number | null; // returns null outside of OE/window shopping
  sepYear: Number | null; // returns null during OE only period
  oeCoverageYear: Number;
  sepCoverageYear: Number;
  nextOEYear: Number;
  lastOEYear: Number;
}

export interface ApolloApplicationIssues {
  id: string;
  dmis: Array<{
    id?: string;
    status?: string;
    statusCodes?: string;
    subType?: string;
    verificationType?: string;
    resolutionStatus?: string;
    resolveBy?: string;
    lastReviewed?: string;
    dependentID?: string;
  }>;
  dmiNotices: Array<{
    id?: string;
    name?: string;
    dsrsIdentifier?: string;
  }>;
  svis: Array<{
    id?: string;
    status?: string;
    statusCode?: string;
    resolveBy?: string;
    lastReviewed?: string;
    dependentIDs?: string;
    generatedPhysicalDocuments: Array<{
      id?: string;
      name?: string;
      dsrsIdentifier?: string;
    }>;
  }>;
  dmiDocumentUploads: Array<{
    id?: string;
    url?: string;
    type?: string;
    issueID?: string;
    createdOn?: string;
    key?: string;
  }>;
  sviDocumentUploads: Array<{
    id?: string;
    url?: string;
    type?: string;
    issueID?: string;
    createdOn?: string;
    key?: string;
  }>;
}

export const HealthPlanFragment = gql`
  fragment HealthPlanDetails on HealthPlan {
    # we don't want this to cache because the pricing (moops, deductibles may change despite the plan ID staying the same)
    planID: id
    name
    title @client
    type
    metalLevel
    metal @client
    isStandard
    premium
    premiumWithCredit
    aptcEligiblePremium
    ehbPremium
    hsaEligible
    brochureURL
    benefitsURL
    issuer {
      id
      title @client
      name
    }
    benefits {
      name
      covered
      costSharings {
        coinsuranceOptions
        coinsuranceRate
        copayAmount
        copayOptions
        networkTier
        csr
        displayString
      }
      explanation
      hasLimits
      limitUnit
      limitQuantity
    }
    moops {
      amount
      familyCost
      type
    }
    deductibles {
      amount
      familyCost
      type
    }
    qualityRating {
      available
      globalRating
      clinicalQualityManagementRating
      enrolleeExperienceRating
      planEfficiencyRating
    }
    individualDeductible @client
    familyDeductible @client
    individualMax @client
    familyMax @client
  }
`;

export const ScoredHealthPlanFragment = gql`
  fragment ScoredHealthPlanDetails on ScoredHealthPlan {
    planID: id
    healthPlan {
      planID: id
      ...HealthPlanDetails
    }
    isCatchRecommended
    isBudgetPick
    isComprehensive
    providerCoverage {
      npi
      name
      isCovered
    }
    drugCoverage {
      rxcui
      name
      isCovered
      isGenericCovered
    }
  }
  ${HealthPlanFragment}
`;

export const PlanSearchFragment = gql`
  fragment PlanSearch on SortedHealthPlansResponse {
    plans {
      planID: id
      ...ScoredHealthPlanDetails
    }
    topPlans {
      catchRecommended {
        planID: id
        ...ScoredHealthPlanDetails
      }
      budgetPlan {
        planID: id
        ...ScoredHealthPlanDetails
      }
      comprehensivePlan {
        planID: id
        ...ScoredHealthPlanDetails
      }
    }
  }
  ${ScoredHealthPlanFragment}
`;

export const EnrollmentGroupFragment = gql`
  fragment EnrollmentGroup on EnrollmentGroup {
    id
    enrollmentType
    selectedProviderPlanID
    planPremium
    planPremiumEffective
    eligibility {
      csrLevel
    }
    members {
      id
      givenName
      familyName
      healthEnrollmentGroupID
    }
    sortedPlans {
      ...PlanSearch
    }
    application {
      id
    }
  }
`;

export const ApplicationMemberFragment = gql`
  fragment ApplicationMember on ApplicationMember {
    id
    applicationID
    relation
    maritalStatus
    givenName
    middleName
    familyName
    nameSuffix
    legalName @client
    filerRelation
    spouseRelation
    sex
    ssn
    age
    dob
    race
    isHispanicOrigin
    ethnicity
    otherRaceText

    isLongTermCare
    isBlindOrDisabled

    sexAssignedAtBirth
    sexAssignedAtBirthOtherText
    gender
    genderDifferentTermText
    sexualOrientation
    sexualOrientationDifferentTermText

    isAmericanIndianAlaskanNative
    isReceiveItu
    isEligibleForItu
    isPersonRecognizedTribe
    federallyRecognizedTribeState
    federallyRecognizedTribeName

    writtenLanguageType
    spokenLanguageType
    contactMethod
    email

    phone {
      number
      ext
      type
    }
    secondaryPhone {
      number
      ext
      type
    }
    mobileNotificationPhoneNumber

    homeAddress {
      street1
      street2
      city
      state
      zip
    }
    mailingAddress {
      street1
      street2
      city
      state
      zip
    }

    lastTobaccoUseDate
    isRequestingCoverage
    isSmoker
    isCitizen
    ssnAlternateName {
      firstName
      middleName
      lastName
    }

    # members questions
    isPregnant
    isFosterCare1825
    isFullTimeStudentStatus
    incarcerationType

    isMedicaidDenied
    isMedicaidEnd
    medicaidDeniedDate
    isAppliedDuringOeOrLifeChange
    isMedicaidEndingSoon
    isInfoChangeSinceMedicaidEnded
    isMedicaidDeniedDueToImmigration
    hasImmigrationStatusFiveYear
    isImmigrationStatusChangeSinceDenied
    medicaidEndDate
    isUnwindingSEP @client

    # SEP
    isLostCoverageLast60Days
    coverageLostDate
    planName
    isLostCoverageLast60Days
    coverageLostDate
    planName
    isWillLoseCoverageNext60Days
    coverageLostDateFuture
    isMarriedLast60Days
    whenMarried
    hasCoverage60DaysBeforeMarriage
    hasLivedOutsideUSLast60DaysBeforeMarriage
    isGainedLawfulPresence
    whenLawfulPresence
    isMoved
    whenRelocated
    hasCoverageLast60DaysBeforeRelocation
    isMovedFromOutsideUs
    previousZipcode
    previousCountyLivedName
    isReleasedFromIncarceration
    whenReleasedFromIncarceration
    isGainDependent
    whenBecomeADependent

    # immigration sections
    uiQuestionsToDisplay
    isLawfulPresenceStatus
    hasLivedInUS5Years
    isVeteran
    isVeteranSelf
    nonMemberVeteranRelationshipTypes
    lawfulPresenceGrantDate
    lawfulPresenceDocumentation {
      documentType
      documentExpirationDate
      sevisID
      i94Number
      passportNumber
      alienNumber
      cardNumber
      passportIssuingCountry
      otherDocumentTypeText
      employmentAuthorizationCategoryIdentifier
      documentAlternativeName {
        firstName
        middleName
        lastName
        suffix
      }
      naturalizationCertificateNumber
      citizenshipNumber
    }
    isNaturalizedCitizen
    preliminaryMedicaidStatus
    preliminaryCHIPStatus
    preliminaryEmergencyMedicaidStatus
    preliminaryQHPStatus
    preliminaryAPTCStatus
    hasAbsentParent

    # income
    incomeThisMonth
    hasDeductions
    annualTaxIncome {
      annualIncomeExplanationRequired
      attestedAnnualizedAPTCIndividualIncomeAmount
      hasReceivedUnemploymentCompensation
      incomeAmount
      isIncomeLessExplained
      isUnknownIncome
      isVariableIncome
      taxHouseholdIncomeDifferenceReasonType
      taxHouseholdIncomeDiscrepancyDescriptionText
      variableIncomeDescriptionText
    }

    incomeSources {
      incomeID
      type
      incomeFrequencyType
      incomeAmount
      incomeDifferenceReason
      averageWeeklyWorkHours
      averageWeeklyWorkDays
      employerName
      employerPhoneNumber
      employerIdentificationNumber
      employerAddress {
        street1
        street2
        city
        state
        zip
      }
      selfEmploymentIncomeDescription
      incomeDescription
      expirationDate
      jobIncomeExplanationRequired
      otherIncomeDifferenceReason
      otherIncomeDescription
      isTribalIncome
      tribalIncomeAmount
    }

    deductions {
      incomeID
      incomeFrequencyType
      incomeAmount
      incomeSourceType
      otherDeductionDescription
    }

    # medical bills
    isUnpaidBill

    # dependents current coverage
    isCoveredDependentChild

    # work hours
    parent1WeeklyWorkHourQuantity
    parent2WeeklyWorkHourQuantity

    # sep
    isLostCoverageLast60Days
    isWillLoseCoverageNext60Days
    coverageLostDate
    coverageLostDateFuture
    planName
    isMarriedLast60Days
    whenMarried
    hasCoverage60DaysBeforeMarriage
    hasLivedOutsideUSLast60DaysBeforeMarriage
    isGainedLawfulPresence
    whenLawfulPresence
    isMoved
    whenRelocated
    hasCoverageLast60DaysBeforeRelocation
    isMovedFromOutsideUs
    previousZipcode
    isReleasedFromIncarceration
    whenReleasedFromIncarceration
    isGainDependent
    whenBecomeADependent

    # other coverages
    isCoverageEnded
    coverageEndedReason
    coverageEndedReasonText
    isEnrolledInHealthCoverage
    otherCoverages {
      insuranceMarketType
      insurancePlanName
      insurancePolicyMemberID
      insurancePolicyNumber
    }
  }
`;

// note: this has already been moved over to the /definitions folder
// but it is still in use elsewhere, so i've left this copy here
export const OpenEnrollmentFragment = gql`
  fragment OEDates on OpenEnrollmentDates {
    windowShoppingOpenTime
    startTime
    endTime
    startDate
    endDate
    isWindowShopping @client
    isOpenEnrollment @client
    coverageYears @client
    sepCoverageYear @client
    oeCoverageYear
    oeYear @client
    sepYear @client
    nextOEYear @client
    lastOEYear @client
  }
`;

gql`
  fragment DMI on DMI {
    id
    status
    statusCodes
    subType
    verificationType
    resolutionStatus
    resolveBy
    lastReviewed
    dependentID
  }
`;

gql`
  fragment DMIUpload on DMIDocumentUpload {
    id
    url
    type
    issueID
    createdOn
    key
  }
`;

gql`
  fragment SVI on SVI {
    id
    status
    statusCode
    resolveBy
    lastReviewed
    generatedPhysicalDocuments {
      id
      name
      dsrsIdentifier
    }
    dependentIDs
  }
`;

gql`
  fragment SVIUpload on SVIDocumentUpload {
    id
    url
    type
    issueID
    createdOn
    key
  }
`;

gql`
  fragment DocNotice on GeneratedPhysicalDocument {
    id
    name
    dsrsIdentifier
  }
`;

gql`
  fragment EDNDocument on GeneratedPhysicalDocument {
    id
    dsrsIdentifier
    documentCreationDate
  }
`;

export const ApplicationIssuesFragment = gql`
  fragment ApplicationIssues on HealthApplication {
    id
    status
    asyncStatus
    aptcHouseholdToApply
    aptcHouseholdAmount
    pathwayType
    allMembers {
      id
      givenName
      familyName
    }
    dmis {
      ...DMI
    }
    dmiDocumentUploads {
      ...DMIUpload
    }
    dmiNotices {
      ...DocNotice
    }
    svis {
      ...SVI
    }
    sviDocumentUploads {
      ...SVIUpload
    }
    sviNotices {
      ...DocNotice
    }
  }
`;
