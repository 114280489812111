import React, { useCallback, useMemo } from 'react';
import { constants, Segment, SegmentEvent, useCopy } from '@app/utils';
import { useQuery, getMembers, HealthApplicationQuery, useMutation, mutations } from '@data';
import { navigate } from '@navigate';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { fields } from './otherCoverageFields';
import { getInitialValues, formatPayload } from './otherCoverageUtils';
import { Route } from '@types';
import { hasMedicaidChipProgram, isMarketplacePrelim } from '@app/navigate/EDENavigationGuard';

const OtherCoverage = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.OtherCoverage');

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { members, filteredMembers, coverageYear, coverageState, stateCHIPWaitingPeriod } =
    useMemo(() => {
      const members = getMembers(data);

      return {
        coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
        coverageState: data?.viewerTwo?.health?.application?.coverageState,
        stateCHIPWaitingPeriod:
          data?.viewerTwo?.health?.application?.stateReferenceData?.stateCHIPWaitingPeriod,
        members,
        filteredMembers: members?.filter(
          (m) =>
            m.preliminaryAPTCStatus === 'YES' ||
            m.preliminaryCHIPStatus === 'YES' ||
            m.preliminaryMedicaidStatus === 'YES',
        ),
      };
    }, [data]);

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const onNext = useCallback(
    (values) => {
      upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: [formatPayload(values)],
          },
        },
      });
    },
    [applicationID],
  );

  const onComplete = () => {
    Segment.track(SegmentEvent.APPLICATION_OTHER_COVERAGE_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (hasMedicaidChipProgram(members)) {
      return navigate(Route.EDE_MEDICAID_CHIP_PROGRAM);
    } else if (isMarketplacePrelim(members)) {
      return navigate(Route.EDE_SEP);
    } else {
      return navigate(Route.EDE_REVIEW);
    }
  };

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      title={(m) => (loading ? 'Coverage history' : c('title', { givenName: m?.givenName }))}
      members={filteredMembers}
      fields={fields}
      data={{
        coverageYear,
        nextYear: coverageYear + 1,
        coverageState,
        stateCHIPWaitingPeriod,
        medicaid: constants.medicaidCHIPNames[coverageState]?.medicaid,
        chip: constants.medicaidCHIPNames[coverageState]?.chip,
      }}
      getInitialValues={(vals) => (vals ? getInitialValues(vals) : {})}
      onNext={onNext}
      onComplete={onComplete}
    />
  );
};

export const OtherCoverageView = {
  name: Route.EDE_OTHER_COVERAGE,
  component: OtherCoverage,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Other coverage',
  },
};
