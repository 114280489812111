import { optionPresets } from '@app/forms';
import { getFullName } from '@app/utils';

export const questions = [
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '149',
    name: 'fosterCare',
    title: { id: '149_L' },
    help: { id: '149_T' },
    show: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['149'],
    filterMembers: (member) => member?.uiQuestionsToDisplay?.['149'],
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isFosterCare1825'],
      subfields: [
        {
          name: 'isFosterCare1825',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => getFullName(values),
        },
        {
          copy: 'catch.ede.HealthEnrollment',
          testID: '150',
          name: 'fosterCareState',
          required: true,
          type: 'dropdown',
          options: optionPresets.STATES,
          dependencies: ['isFosterCare1825', 'givenName', 'familyName'],
          display: ({ isFosterCare1825 }) => !!isFosterCare1825,
          label: (values) => ({ id: '150_Q', data: { fullName: getFullName(values) } }),
        },
        {
          copy: 'catch.ede.HealthEnrollment',
          testID: '151',
          name: 'isMedicaidDuringFosterCare',
          required: true,
          type: 'option',
          options: optionPresets.YES_NO_TRINARY,
          dependencies: ['isFosterCare1825', 'givenName', 'familyName', 'medicaid'],
          display: ({ isFosterCare1825 }) => !!isFosterCare1825,
          label: (values) => ({
            id: '151_Q',
            data: {
              fullName: getFullName(values),
              medicaid: values.medicaid,
            },
          }),
        },
        {
          copy: 'catch.ede.HealthEnrollment',
          testID: '152',
          name: 'fosterCareEndAge',
          required: true,
          type: 'number',
          numberType: 'integer',
          placeholder: 'Age',
          dependencies: ['isFosterCare1825', 'givenName', 'familyName'],
          display: ({ isFosterCare1825 }) => !!isFosterCare1825,
          label: (values) => ({ id: '152_Q', data: { fullName: getFullName(values) } }),
        },
      ],
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '146',
    name: 'americanIndianAlaskanNative',
    title: { id: '146_L' },
    help: { id: '146_T' },
    show: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['146'],
    filterMembers: (member) => member?.uiQuestionsToDisplay?.['146'],
    field: {
      name: 'members',
      type: 'array',
      subfields: [
        {
          name: 'isAmericanIndianAlaskanNative',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => getFullName(values),
        },
      ],
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '250',
    name: 'isIncarcerated',
    title: { id: '250_L' },
    help: { id: '250_T' },
    show: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['250'],
    filterMembers: (member) => member?.uiQuestionsToDisplay?.['250'],
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isIncarcerated'],
      subfields: [
        {
          name: 'isIncarcerated',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => getFullName(values),
        },
        {
          copy: 'catch.ede.HealthEnrollment',
          testID: '251',
          name: 'incarcerationType',
          required: true,
          type: 'option',
          dependencies: ['isIncarcerated', 'givenName', 'familyName'],
          options: optionPresets.YES_NO_TRINARY,
          display: ({ isIncarcerated }) => !!isIncarcerated,
          label: (values) => ({ id: '251_Q', data: { fullName: getFullName(values) } }),
          help: { id: '251_T' },
        },
      ],
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '147',
    name: 'pregnant',
    title: { id: '147_L' },
    help: { id: '147_T' },
    show: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['147'],
    filterMembers: (member) => member?.uiQuestionsToDisplay?.['147'],
    field: {
      name: 'members',
      type: 'array',
      dependencies: ['SUBFIELD:isPregnant'],
      subfields: [
        {
          name: 'isPregnant',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => getFullName(values),
        },
        {
          copy: 'catch.ede.HealthEnrollment',
          testID: '148',
          name: 'babyDueQuantity',
          required: true,
          type: 'dropdown',
          options: Array.from({ length: 8 }, (_, i) => ({ label: i + 1, value: i + 1 })),
          dependencies: ['isPregnant', 'givenName', 'familyName'],
          display: ({ isPregnant }) => !!isPregnant,
          label: (values) => ({ id: '148_Q', data: { fullName: getFullName(values) } }),
        },
      ],
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '144',
    name: 'fullTimeStudent',
    title: { id: '144_L' },
    help: { id: '144_T' },
    show: ({ uiQuestionsToDisplay }) => uiQuestionsToDisplay?.['144'],
    filterMembers: (member) => member?.uiQuestionsToDisplay?.['144'],
    field: {
      name: 'members',
      type: 'array',
      subfields: [
        {
          name: 'isFullTimeStudentStatus',
          bare: true,
          type: 'person',
          dependencies: ['givenName', 'familyName'],
          label: (values) => getFullName(values),
        },
      ],
    },
  },
];
